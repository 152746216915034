import { CRUD, http } from "@/api";

export const Necessidade = new CRUD("/eqpdti/necessidades");

export const Contrato = new CRUD("/contratos");

export const Inventario = new CRUD("/recursos");

export const Demanda = new CRUD("/demandas");

export const Meta = new CRUD("/metas");

export const Acao = new CRUD("/acoes");

export const RecursoHumano = new CRUD("/recursos-humanos");

export const HabilidadeServidor = new CRUD("/habilidades-servidor");

export const PDTICAnterior = new CRUD("/pdtic-anterior");

export const SolicitacaoItem = new CRUD("/solicitacao-item");

export const ResultadosMetas = new CRUD("/resultados-metas");

export const InventarioUploadCsv = new CRUD("/upload-csv-recursos");

//Equipe de Elaboração

export const DocumentoReferencia = new CRUD("/eqpdti/documentos-referencia");
export const Secao = new CRUD("/eqpdti/sections");
export const ObjetivoEstrategico = new CRUD("/eqpdti/objetivos-estrategicos");
export const Swot = new CRUD("/eqpdti/swot");
export const PrincipioDiretriz = new CRUD("/eqpdti/principios-diretrizes");
export const PlanejamentoEstrategico = new CRUD(
  "/eqpdti/planejamento-estrategico"
);

RecursoHumano.search = function(username) {
  return http.get("/users/search/", {
    params: {
      username,
    },
  });
};

RecursoHumano.sendMail = function(id) {
  return http.post(`${this.uri}/${id}/send-mail/`);
};

RecursoHumano.perfil = function(uid) {
  return http.get(`/perfil-servidor/${uid}/`);
};

RecursoHumano.savePerfil = function(uid, data) {
  return http.put(`/perfil-servidor/${uid}/`, data);
};

HabilidadeServidor.list = function(uid) {
  return http.get("/habilidades-servidor/", {
    params: {
      servidor: uid,
    },
  });
};

HabilidadeServidor.update = function(uid, data) {
  return http.put("/habilidades-servidor/", data, {
    params: {
      servidor: uid,
    },
  });
};

HabilidadeServidor.post = function(uid, data) {
  return http.post("/habilidades-servidor/", data, {
    params: {
      servidor: uid,
    },
  });
};

Acao.list = function(metaId) {
  if (!metaId) {
    metaId = "";
  }
  return http.get(`${this.uri}`, {
    params: {
      meta: metaId,
    },
  });
};

Inventario.deleteData = function() {
  return http.post(`${this.uri}/deletar-recursos/`);
}
