<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    :items="items"
    item-text="nome"
    item-value="id"
    :filter="filter"
    :loading="loading"
    label="Unidade Organizacional"
  >
    <template v-slot:item="{ item }">
      <v-row dense>
        <v-col cols="12">
          <v-card max-width="500" elevation="0" color="transparent">
            <v-card-title class="item-text">
              {{ item.sigla }} - {{ item.nome }}
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </v-autocomplete>
</template>

<script>
import { UnidadeOrganizacional } from "@/api/core";
export default {
  name: "UOAutoComplete",
  data: () => ({
    items: [],
    search: null,
    loading: false,
  }),

  methods: {
    filter(item, queryText) {
      const sigla = item.sigla.toLowerCase();
      const nome = item.nome.toLowerCase();
      const search = queryText.toLowerCase();
      return sigla.indexOf(search) > -1 || nome.indexOf(search) > -1;
    },
  },
  mounted() {
    this.loading = true;
    UnidadeOrganizacional.list().then((response) => {
      this.loading = false;
      this.items = response.data;
    });
  },
};
</script>
