<template>
  <v-data-table
    class="elevation-1"
    :items="data"
    :headers="computedHeaders"
    :loading="tableLoading"
  >
    <template v-slot:item.actions="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" small class="mr-2" @click="edit(item)">
            mdi-pencil
          </v-icon>
        </template>
        <span>Editar</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" small @click="remove(item)"> mdi-delete </v-icon>
        </template>
        <span>Remover</span>
      </v-tooltip>
    </template>
    <template v-slot:item.valor-anual="{ item }">
      {{
        Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(item.valor_anual)
      }}
    </template>
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Contratos</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="600px" @click:outside="close">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"
              >Novo</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Contrato</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-row v-if="$state.role != 'eqpdtiuo'">
                      <UOAutoComplete
                        v-model="Item.uo"
                        :error-messages="errors['uo']"
                      />
                    </v-row>
                    <v-row>
                      <v-text-field
                        v-model="Item.objeto"
                        label="Objeto de contrato"
                        :error-messages="errors['objeto']"
                      ></v-text-field>
                    </v-row>
                    <v-row>
                      <v-text-field
                        v-model="Item.empresa_contratada"
                        label="Contratado"
                        :error-messages="errors['empresa_contratada']"
                      ></v-text-field>
                    </v-row>
                    <v-row>
                      <v-money
                        v-model="Item.valor_anual"
                        label="Valor Anual"
                        :error-messages="errors['valor_anual']"
                      ></v-money>
                    </v-row>
                    <v-row>
                      <v-textarea
                        v-model="Item.descricao"
                        label="Descrição"
                        :error-messages="errors['descricao']"
                      ></v-textarea>
                    </v-row>
                    <v-row>
                      <v-textarea
                        v-model="Item.beneficios"
                        label="Benefícios"
                        :error-messages="errors['beneficios']"
                      ></v-textarea>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="darken-1" text @click="close">Cancelar</v-btn>
              <v-btn :loading="saving" color="darken-1" text @click="save"
                >Salvar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
  </v-data-table>
</template>

<script>
import { Contrato } from "@/api/eqpdti";
import UOAutoComplete from "@/components/UOAutoComplete";
import VMoney from "@/components/VMoney";
export default {
  name: "Contrato",
  components: {
    UOAutoComplete,
    'v-money': VMoney,
  },
  data: () => ({
    data: [],
    headers: [
      {
        text: "Objeto",
        value: "objeto",
        align: "left",
      },
      {
        text: "Contratado",
        value: "empresa_contratada",
        align: "left",
      },
      {
        text: "Valor anual",
        value: "valor-anual",
        align: "center",
      },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "end",
      },
    ],
    Item: {
      inicio: "",
      fim: "",
    },
    dialog: false,
    saving: false,
    tableLoading: false,
    errors: [],
  }),
  computed: {
    computedHeaders() {
      this.fetch();
      if (this.$state.role != "eqpdtiuo") {
        return [
          {
            text: "Unidade Organizacional",
            value: "uo_sigla",
            align: "center",
          },
        ].concat(this.headers);
      }
      return this.headers;
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.Item = {};
      });
    },
    save() {
      this.saving = true;
      this.errors = [];
      const _then = () => {
        this.saving = false;
        this.close();
        this.fetch();
      };
      const _catch = (error) => {
        this.saving = false;
        this.errors = error.response.data;
        if (this.errors["non_field_errors"]) {
          this.$emitter.emit("alert:error", this.errors["non_field_errors"][0]);
        } else {
          this.$emitter.emit("alert:error", this.errors);
        }
      };

      if (this.Item["id"]) {
        let id = this.Item["id"];

        Contrato.update(id, this.Item).then(_then).catch(_catch);
      } else {
        Contrato.post(this.Item).then(_then).catch(_catch);
      }
    },
    edit(item) {
      this.Item = Object.assign({}, item);
      this.dialog = true;
    },

    remove(item) {
      if (window.confirm("Deseja realmente excluir?")) {
      this.tableLoading = true;
      Contrato.remove(item["id"]).then(() => {
        this.tableLoading = false;
        this.fetch();
      });
      }
    },
    fetch() {
      this.tableLoading = true;
      Contrato.list().then((response) => {
        this.data = response.data;
        this.tableLoading = false;
      });
    },
  },
  // mounted() {
  //   this.fetch();
  // },
};
</script>
